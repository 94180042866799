import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Nav from "./Nav";
import styled from "styled-components";
import Footer from "./Footer";
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css";
import { Toaster } from "react-hot-toast";

const SmallHeroStyles = styled.div`
  min-height: 20rem;
  position: relative;

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    filter: brightness(60%);
  }
`;

const SmallHero = () => {
  return (
    <SmallHeroStyles>
      <StaticImage
        src="../images/hero-s.jpg"
        layout="fullWidth"
        alt=""
        placeholder="blurred"
      />
    </SmallHeroStyles>
  );
};

const Layout = ({ children, path }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <Toaster
        toastOptions={{
          duration: 6000,
          style: {
            fontSize: "1.6rem",
            fontWeight: "400",
            textAlign: "center",
          },
        }}
      />
      {path !== "/" && <SmallHero />}
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
