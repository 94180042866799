import { createGlobalStyle } from "styled-components";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/300.css";

const Typography = createGlobalStyle`
    body {
        font-family: 'Poppins';
        color: var(--darkBlue);
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: normal;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: unset;
        transition: 0.3s all ease-in-out;
    }
`;

export default Typography;
