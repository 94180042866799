import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --orange: #ED9C45;
    --darkBlue: #2D2D45;
    --lighterDarkBlue: #2d2d459c;
    --green: #00BA00;
    --bs: 0px 14px 32px 0px rgb(0 0 0 / 10%);
    --lightBorder: 1px solid rgba(73, 73, 73, 0.08);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }


  body {
    font-size: 2rem;

    &.no-scroll {
      margin: 0;
      height: 100%;
      overflow: hidden;
    }
  }
  
  main {
    padding-bottom: 5rem;
    min-height: 50vh;
  }

  img {
    max-width: 100%;
  }

  a.button {
    cursor: pointer;
    transition: 0.3s all ease;
    &:hover {
      filter: brightness(1.05);
    }
  }

  .form-button {
    border: none;
    border-radius: 0.5rem;
    font-size: 1.7rem;
    padding: 1rem 2rem;
    cursor: pointer;
    font-weight: 300;
    transition: 0.3s all ease-in;

    &:hover {
      filter: brightness(1.05);
    }
    
    &.success {
      background: var(--green);
      color: white;
    }
  }

  fieldset {
    border: none;

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .breadcrumb {
    background: rgba(211,211,211,0.3);
    padding: 1rem 2rem;
  }

  .breadcrumb__link, 
  .breadcrumb__separator {
   font-weight: 300;
  }

  .breadcrumb__link__active {
    color: var(--orange);
  }

  form {
    font-size: 1.6rem;
    .field {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;

    label {
      margin: 1rem 0;
    }

    .input,
    .select {
      height: 2.5em;
    }

    .input,
    .select,
    textarea {
      font-weight: 300;
      background-color: #fff;
      border: 1px solid #dbdbdb;
      border-color: #dbdbdb;
      border-radius: 4px;
      padding: 0.5em;
      width: 100%;
      resize: none;
      -webkit-appearance: none;

      &::placeholder {
        font-weight: 400;
        color: lightgray;
      }

      &:focus {
        outline: 0;
        border-color: #485fc7;
        box-shadow: 0 0 0 0.125em rgb(72 95 199 / 25%);
      }
    }

    select {
      -webkit-appearance: none;
    }
  }
  
  .buttons {
    display: flex;
    padding: 1.5rem;
    .form-button:last-of-type {
      margin-left: 1rem;
    }
  }
  
`;

export default GlobalStyles;
