exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-pages-what-to-see-js": () => import("./../../../src/pages/what-to-see.js" /* webpackChunkName: "component---src-pages-what-to-see-js" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-an-interview-with-sabine-ladstatter-on-the-ancient-city-of-ephesus-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/blog/an-interview-with-sabine-ladstatter-on-the-ancient-city-of-ephesus.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-an-interview-with-sabine-ladstatter-on-the-ancient-city-of-ephesus-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-cats-of-ephesus-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/blog/cats-of-ephesus.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-cats-of-ephesus-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-history-of-ephesus-ephesus-chronology-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/blog/history-of-ephesus-ephesus-chronology.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-history-of-ephesus-ephesus-chronology-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-what-makes-ephesus-so-important-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/blog/what-makes-ephesus-so-important.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-what-makes-ephesus-so-important-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-where-is-ephesus-how-to-get-there-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/blog/where-is-ephesus-how-to-get-there.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-blog-where-is-ephesus-how-to-get-there-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-arcadian-harbour-way-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/arcadian-harbour-way.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-arcadian-harbour-way-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-commercial-agora-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/commercial-agora.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-commercial-agora-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-great-theatre-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/great-theatre.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-great-theatre-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-prytaneion-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/prytaneion.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-prytaneion-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-street-of-the-curetes-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/street-of-the-curetes.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-street-of-the-curetes-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temenos-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/temenos.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temenos-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temple-of-artemis-artemision-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/temple-of-artemis-artemision.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temple-of-artemis-artemision-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temple-of-the-state-agora-isis-temple-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/temple-of-the-state-agora-isis-temple.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-temple-of-the-state-agora-isis-temple-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-house-of-the-virgin-mary-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-house-of-the-virgin-mary.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-house-of-the-virgin-mary-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-library-of-celsus-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-library-of-celsus.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-library-of-celsus-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-monument-of-memmius-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-monument-of-memmius.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-monument-of-memmius-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-odeon-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-odeon.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-odeon-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-temple-of-domitian-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-temple-of-domitian.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-temple-of-domitian-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-terrace-houses-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/landmarks/the-terrace-houses.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-landmarks-the-terrace-houses-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-news-board-game-studies-colloquium-ephesus-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/news/board-game-studies-colloquium-ephesus.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-news-board-game-studies-colloquium-ephesus-mdx" */),
  "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-news-covid-19-update-our-precautions-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/news/covid-19-update-our-precautions.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-opt-build-repo-src-news-covid-19-update-our-precautions-mdx" */),
  "component---src-templates-tour-template-js": () => import("./../../../src/templates/tour-template.js" /* webpackChunkName: "component---src-templates-tour-template-js" */)
}

