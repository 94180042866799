import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

const FooterStyles = styled.footer`
  min-height: 30rem;
  background: var(--darkBlue);
  padding: 3rem 1rem;
  position: relative;
  .content {
    margin: 0 auto;
    max-width: 1080px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    justify-content: center;
    grid-gap: 1rem;
    color: white;
    padding-bottom: 2rem;
  }

  .copyright {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.2rem;
    font-weight: 200;
  }
`;

const FooterLeft = styled.div`
  height: 22rem;
  padding-top: 3rem;
  a {
    max-width: fit-content;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem 0;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid rgba(211, 211, 211, 0.08);
  }
  .logos {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    .gatsby-image-wrapper {
      object-fit: contain;
      margin: 0 1rem;
    }
  }
`;

const FooterRight = styled.div`
  height: 22rem;
  padding: 2rem 1rem 0 1rem;
  .lists {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    grid-gap: 2rem;
    ul {
      list-style: none;
      padding: 0 2rem;
      li.head {
        font-weight: 300;
        color: white;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(211, 211, 211, 0.08);
        max-width: fit-content;
      }

      li {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.6rem;
        font-weight: 200;
        margin: 1rem 0;
      }
    }
  }

  .socials {
    max-width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 30px);
    grid-gap: 1rem;
    justify-content: end;

    svg {
      margin-top: -2rem;
      stroke-width: 1;
    }
  }
`;

const Footer = () => {
  return (
    <FooterStyles>
      <div className="content">
        <FooterLeft>
          <a href="mailto:info@helloephesus.com">✉️ info@helloephesus.com</a>
          <div className="logos">
            <StaticImage
              src="../images/safe-travels.png"
              height={80}
              alt="Safe Travels Certified"
            />
            <StaticImage
              src="../images/tripadvisor-choice.png"
              height={80}
              alt="TripAdvisor Travelers' Choice"
            />
            <StaticImage
              src="../images/tursab.png"
              height={80}
              alt="Member of Association of Turkish Travel Agencies"
            />
          </div>
        </FooterLeft>
        <FooterRight>
          <div className="lists">
            <ul>
              <li className="head">Hello Ephesus</li>
              <li>
                <Link to="/tours">Tours</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/faq">F.A.Q.</Link>
              </li>
            </ul>
            <ul>
              <li className="head">All About Ephesus</li>
              <li>
                <Link to="/what-to-see">What to See?</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
            </ul>
          </div>
          <div className="socials">
            <a href="https://www.instagram.com/helloephesus" target="_blank">
              {" "}
              <FeatherIcon icon="instagram" height={40} />
            </a>
            <a href="https://www.facebook.com/helloephesus" target="_blank">
              {" "}
              <FeatherIcon icon="facebook" height={40} />
            </a>
          </div>
        </FooterRight>
      </div>
      <span className="copyright">
        {new Date().getFullYear()} © Hello Ephesus. All rights reserved
      </span>
    </FooterStyles>
  );
};

export default Footer;
