import React from "react";
import Layout from "./src/components/Layout";
import "normalize.css";
import { BookingProvider } from "./src/components/BookingContext";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <BookingProvider>{element}</BookingProvider>;
}
