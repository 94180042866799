module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TVDBLKZ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-106819565-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbLabelUpdates":[{"pathname":"/tours","crumbLabel":"Tours"},{"pathname":"/what-to-see","crumbLabel":"What to See"},{"pathname":"/news","crumbLabel":"News"},{"pathname":"/blog","crumbLabel":"Blog"}]},
    },{
      plugin: require('../node_modules/@honeybadger-io/gatsby-plugin-honeybadger/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"hbp_S0xPsKE7YZsoBSRZV2dsK3A3rB4zxs4r4Gyz","revision":"1728573288280","assetsUrl":"https://www.helloephesus.com/","environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
