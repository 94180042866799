import { useStaticQuery } from "gatsby";
import React, { createContext, useState, useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import tomorrow from "../utils/tomorrow";

const BookingContext = createContext();

function BookingProvider({ children }) {
  const data = useStaticQuery(graphql`
    query {
      tours {
        clientConfigOptions {
          departureTimeOptions
          minGroupSize
        }
      }
    }
  `);

  const {
    tours: { clientConfigOptions },
  } = data;

  const initialBookingState = {
    lead_traveler: "",
    email: "",
    pax: clientConfigOptions.minGroupSize,
    tour_time: "",
    guest_note: "",
    pickup_type: "",
    pickup_details: "",
    tour_date: tomorrow(),
  };

  const [booking, setBooking] = useState({ ...initialBookingState });
  const [bookingFormActive, setBookingFormActive] = useState(false);

  function resetBooking() {
    setBooking({ ...initialBookingState });
  }

  return (
    <BookingContext.Provider
      value={{
        booking,
        setBooking,
        bookingFormActive,
        setBookingFormActive,
        departureTimeOptions: clientConfigOptions.departureTimeOptions,
        minGroupSize: clientConfigOptions.minGroupSize,
        resetBooking,
      }}
    >
      <Helmet
        bodyAttributes={bookingFormActive ? { class: "no-scroll" } : {}}
      />
      {children}
    </BookingContext.Provider>
  );
}

function useBooking() {
  const all = useContext(BookingContext);

  return all;
}

export { BookingProvider, useBooking };
