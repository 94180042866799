import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const NavStyles = styled.nav`
  height: 7rem;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ul {
    list-style: none;
    width: 400px;
    display: flex;
    justify-content: space-around;

    a {
      font-size: 1.6rem;
      color: #2d2d45;

      &:hover {
        color: var(--orange);
      }

      &[aria-current] {
        color: var(--orange);
      }
    }
  }

  .burger {
    display: none;
    cursor: pointer;

    div {
      width: 25px;
      height: 2px;
      background-color: #2d2d45;
      margin: 5px;
      transition: all 0.3s ease;
    }

    &.toggle {
      z-index: 999;
      .line1 {
        transform: rotate(-45deg) translate(-5px, 4px);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(45deg) translate(-5px, -5px);
      }
    }
  }

  @media (max-width: 769px) {
    padding: initial 6rem;
    justify-content: space-between;

    ul {
      flex-direction: column;
      background: white;
      position: fixed;
      justify-content: center;
      align-items: center;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      animation: slideOut 0.5s ease-in-out forwards;

      li {
        margin: 2rem 0;
        opacity: 0;
        transition: 2s ease-in-out all;
      }

      a {
        font-size: 2.8rem;
        font-weight: 400;
      }
    }

    .burger {
      display: block;
    }

    &.mobile-active {
      ul {
        animation: slideIn 0.8s ease-in-out forwards;
        z-index: 999;

        li {
          animation: linkAppear 2s ease-in-out forwards;
        }
      }

      @keyframes slideOut {
        to {
          transform: translateX(100%);
        }
      }

      @keyframes slideIn {
        from {
          transform: translateX(100%);
        }

        to {
          transform: translateX(0);
        }
      }

      @keyframes linkAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    }
  }
`;

const Nav = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  return (
    <>
      <Helmet
        bodyAttributes={mobileMenuActive ? { class: "no-scroll" } : {}}
      />
      <NavStyles className={mobileMenuActive && "mobile-active"}>
        <Link to="/">
          <StaticImage
            src={"../images/logo.svg"}
            alt="Hello Ephesus"
            layout="fixed"
            height={50}
            placeholder="none"
          />
        </Link>
        <ul>
          <li>
            <Link to="/" onClick={() => setMobileMenuActive(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/tours" onClick={() => setMobileMenuActive(false)}>
              Tours
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setMobileMenuActive(false)}>
              Contact
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setMobileMenuActive(false)}>
              About
            </Link>
          </li>
        </ul>
        <div
          className={`burger ${mobileMenuActive && "toggle"}`}
          onClick={() => setMobileMenuActive(!mobileMenuActive)}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </NavStyles>
    </>
  );
};

export default Nav;
